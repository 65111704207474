import { Formik } from "formik";
import * as React from "react";
import { useEffect, useState } from "react";
import {
    Button,
    Col,
    Form,
    OverlayTrigger,
    Popover,
    Row,
} from "react-bootstrap";
import FeatureTypeRendering from "../components/common/FeatureTypeRendering";
import { useTypedDispatch } from "../store/hooks/useTypedDispatch";
import { useTypedSelector } from "../store/hooks/useTypedSelector";
import { authActions } from "../store/reducers/authSlice";
import { actions } from "../store/reducers/subscriptionSlice";
import { Feature } from "../types/Feature";
import { Plantype } from "../types/PlanType";
import { getFileUrl } from "../helpers/getFileUrl";
import ProfileBtnPopover from "../components/navBar/ProfileBtnPopover";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";

const Billing: React.FC = () => {
    const dispatch = useTypedDispatch();
    const [currentState, setCurrentState] = useState<"monthly" | "annually">(
        "monthly"
    );
    const user = useTypedSelector((state) => state.auth.currentUser);

    const userInitials = `
        ${user?.nameFirst[0]}${user?.nameLast[0]}
    `.toUpperCase();
    const [selected, setSelected] = useState<string | number | undefined>("");

    const { fetchPlans } = actions;
    const { list } = useTypedSelector((state) => state.subscription.plans);

    useEffect(() => {
        dispatch(
            fetchPlans({
                fetchActive: "true",
            })
        );
    }, []);

    const initialValues = {};
    const { logOutToken } = authActions;
    const popupLogout = (props: any) => {
        return (
            <Popover id="popover-profile" {...props}>
                <Popover.Content>
                    <ul className="p-0 m-0">
                        <li
                            onClick={() => dispatch(logOutToken())}
                            className="d-flex align-items-center cursor-pointer bg-hover-gray-200 px-2 py-1 rounded"
                        >
                            <FontAwesomeIcon
                                icon={faSignOutAlt}
                                className="mr-2 w-15px h-15px text-secondary"
                            />
                            <p className="p3 mb-0">Log out</p>
                        </li>
                    </ul>
                </Popover.Content>
            </Popover>
        );
    };

    return (
        <div className="container-fluid">
            <Row>
                <Col xl={12}>
                    <div
                        className="card"
                        id="kt_pricing"
                        style={{ minHeight: "100vh" }}
                    >
                        <Formik
                            initialValues={initialValues}
                            onSubmit={async (values, { setSubmitting }) => {
                                setSubmitting(true);
                                try {
                                    window.location.href =
                                        "/add-card-step/plan/" + selected;
                                    // eslint-disable-next-line no-empty
                                } catch (err) {}
                            }}
                        >
                            {({ handleSubmit }) => (
                                <Form onSubmit={handleSubmit}>
                                    {/*begin::Card body*/}
                                    <div className="card-body p-lg-17">
                                        {/*begin::Plans*/}
                                        <div className="d-flex flex-column">
                                            {/*begin::Heading*/}
                                            <div className="mb-13 text-center">
                                                <div className="mb-lg-10">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <h1 className="h1 font-weight-bolder text-center">
                                                                Welcome to
                                                                Sparty
                                                            </h1>
                                                        </div>
                                                        <OverlayTrigger
                                                            trigger="click"
                                                            placement="bottom"
                                                            rootClose
                                                            overlay={
                                                                popupLogout
                                                            }
                                                        >
                                                            <div className="rounded-circle overflow-hidden h-40px w-40px ml-sm-4 ml-0 cursor-pointer bg-light">
                                                                {user?.profilePic ? (
                                                                    <img
                                                                        src={getFileUrl(
                                                                            user.profilePic
                                                                        )}
                                                                        alt=""
                                                                        className="h-40px w-40px rounded-circle d-flex  justify-content-center align-items-center text-center"
                                                                    />
                                                                ) : (
                                                                    <div className="bg-gray-500 h-100 w-100 d-flex justify-content-center align-items-center">
                                                                        <p className="user-select-none mb-0 font-weight-bold">
                                                                            {
                                                                                userInitials
                                                                            }
                                                                        </p>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </OverlayTrigger>
                                                    </div>
                                                    <p className="text-muted font-weight-bold p2 text-center">
                                                        Select Membership Plan
                                                    </p>
                                                </div>
                                            </div>
                                            {/*end::Heading*/}
                                            {/*begin::Nav group*/}
                                            <div
                                                className="nav-group nav-group-outline mx-auto mb-15"
                                                data-kt-buttons="true"
                                            >
                                                <a
                                                    href="#"
                                                    className={
                                                        "btn btn-color-gray-400 btn-active btn-active-secondary px-6 py-3 me-2 " +
                                                        (currentState ===
                                                            "monthly" &&
                                                            "active btn-bg-light")
                                                    }
                                                    onClick={() => {
                                                        setCurrentState(
                                                            "monthly"
                                                        );
                                                    }}
                                                    data-kt-plan="monthly"
                                                >
                                                    Monthly
                                                </a>
                                                <a
                                                    href="#"
                                                    className={
                                                        "btn btn-color-gray-400 btn-active btn-active-secondary px-6 py-3 me-2 " +
                                                        (currentState ===
                                                            "annually" &&
                                                            "active btn-bg-light")
                                                    }
                                                    onClick={() => {
                                                        setCurrentState(
                                                            "annually"
                                                        );
                                                    }}
                                                    data-kt-plan="annually"
                                                >
                                                    Annual
                                                </a>
                                            </div>
                                            {/*end::Nav group*/}
                                            {/*begin::Row*/}
                                            <div className="row g-10 justify-content-center">
                                                {/*begin::Col*/}
                                                {list
                                                    ?.filter(
                                                        (x: Plantype) =>
                                                            x?.recurrence ==
                                                            currentState
                                                    )
                                                    ?.map((plan: Plantype) => {
                                                        return (
                                                            <div
                                                                className="col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-3"
                                                                key={plan.id}
                                                            >
                                                                <div className="d-flex h-100 align-items-center">
                                                                    {/*begin::Option*/}
                                                                    <div
                                                                        className="w-100 h-100 d-flex flex-column flex-start align-items-center rounded-3 bg-light bg-opacity-75 py-15 px-10"
                                                                        style={
                                                                            selected ==
                                                                            plan?.id
                                                                                ? {
                                                                                      border: "1px solid #3699FF",
                                                                                      cursor: "pointer",
                                                                                  }
                                                                                : {
                                                                                      border: "1px solid transparent",
                                                                                      cursor: "pointer",
                                                                                  }
                                                                        }
                                                                        onClick={() =>
                                                                            setSelected(
                                                                                plan?.id
                                                                            )
                                                                        }
                                                                    >
                                                                        {/*begin::Heading*/}
                                                                        <div className="mb-15 text-center">
                                                                            {/*begin::Title*/}
                                                                            <h1 className="text-dark text-uppercase h1 mb-5 font-weight-bolder">
                                                                                {
                                                                                    plan?.name
                                                                                }
                                                                            </h1>
                                                                            {/*end::Title*/}
                                                                            {/*begin::Description*/}
                                                                            {/* <div className="text-gray-400 fw-semibold mb-5">
                                                        Optimal for 10+ team
                                                        size
                                                        <br /> and new
                                                        startup
                                                    </div> */}
                                                                            {/*end::Description*/}
                                                                            {/*begin::Price*/}
                                                                            <div className="text-center">
                                                                                <span className="fs-3x fw-bolder h1 text-primary">
                                                                                    $
                                                                                </span>
                                                                                <span className="fs-3x fw-bolder h1 text-primary">
                                                                                    {
                                                                                        plan?.price
                                                                                    }
                                                                                </span>

                                                                                <span className="fs-7 fw-semibold opacity-50">
                                                                                    /
                                                                                    <span data-kt-element="period">
                                                                                        {currentState ===
                                                                                        "monthly"
                                                                                            ? "Mo"
                                                                                            : "Year"}
                                                                                    </span>
                                                                                </span>
                                                                            </div>
                                                                            {/*end::Price*/}
                                                                        </div>
                                                                        {/*end::Heading*/}
                                                                        {/*begin::Features*/}
                                                                        <div className="w-100 mb-5">
                                                                            {plan
                                                                                ?.features
                                                                                ?.length &&
                                                                                plan?.features?.map(
                                                                                    (
                                                                                        feature: Feature,
                                                                                        index: number
                                                                                    ) => {
                                                                                        return (
                                                                                            <div
                                                                                                key={
                                                                                                    feature?.key ||
                                                                                                    index
                                                                                                }
                                                                                                className="d-flex align-items-center mb-6"
                                                                                            >
                                                                                                <span className="font-weight-bold h6 text-gray-700 flex-grow-1">
                                                                                                    {
                                                                                                        feature?.fname
                                                                                                    }
                                                                                                </span>
                                                                                                {
                                                                                                    <FeatureTypeRendering
                                                                                                        featureProp={
                                                                                                            feature
                                                                                                        }
                                                                                                    ></FeatureTypeRendering>
                                                                                                }
                                                                                            </div>
                                                                                        );
                                                                                    }
                                                                                )}
                                                                        </div>
                                                                        {/*end::Features*/}
                                                                        {/*begin::Select*/}
                                                                        {/* <a
                                                                            href="#"
                                                                            className="btn btn-sm btn-primary"
                                                                            style={{
                                                                                marginTop:
                                                                                    "auto",
                                                                                minWidth:
                                                                                    "200px",
                                                                            }}
                                                                            onClick={() =>
                                                                                setSelected(
                                                                                    plan?.stripe_price_id
                                                                                )
                                                                            }
                                                                        >
                                                                            Select
                                                                        </a> */}
                                                                        {/*end::Select*/}
                                                                    </div>
                                                                    {/*end::Option*/}
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                            </div>
                                            {/*end::Row*/}
                                            <div className="mt-5">
                                                <Form.Row className="d-flex justify-content-center">
                                                    <Button
                                                        size="lg"
                                                        variant="primary"
                                                        type="submit"
                                                        className="mr-5"
                                                        disabled={
                                                            selected
                                                                ? false
                                                                : true
                                                        }
                                                    >
                                                        Subscribe
                                                    </Button>
                                                    <Button
                                                        size="lg"
                                                        variant="secondary"
                                                        onClick={() =>
                                                            dispatch(
                                                                logOutToken()
                                                            )
                                                        }
                                                    >
                                                        Log Out
                                                    </Button>
                                                </Form.Row>
                                            </div>
                                        </div>
                                        {/*end::Plans*/}
                                    </div>
                                    {/*end::Card body*/}
                                </Form>
                            )}
                        </Formik>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default Billing;
