import React from "react";
import { Accordion, Card } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { useTypedDispatch } from "../../store/hooks/useTypedDispatch";
import { useTypedSelector } from "../../store/hooks/useTypedSelector";
import { actions } from "../../store/reducers/announcementSlice";
import { actions as cActions } from "../../store/reducers/contactSlice";
import Loading from "../common/Loading";
import "./contact.scss";
import ManageAnnouncementPopover from "./ManageAnnouncementPopover";

interface IProps {
    announcement: any;
    isLoading?: boolean;
    name?: string;
}
const AnnouncementList: React.FC<IProps> = ({
    name,
    announcement,
    isLoading,
}) => {
    const dispatch = useTypedDispatch();
    const { fetchAnnouncementsContacts, selectOne } = actions;
    const { list: announcementContactlist, isLoading: checkLoading } =
        useTypedSelector((state) => state.announcement.announcementContactList);
    // Assuming you are using this function to handle a click event on an accordion item
    const handleAccordionToggle = async (announcement: any) => {
        dispatch(
            cActions.setSelected({
                type: "announcement",
                entity: announcement,
            })
        );
        dispatch(selectOne(announcement));
        dispatch(fetchAnnouncementsContacts(announcement?.id || 0))
            .unwrap()
            .then((data: any) => {
                return data;
            });
    };

    if (isLoading && (!announcement || announcement.length === 0))
        return (
            <div>
                {new Array(5).fill(0).map((_, index) => (
                    <div
                        key={index}
                        className="d-flex align-items-center mb-1 px-2 py-1 list-view__item rounded"
                    >
                        <div className="rounded-circle shadow-sm skeleton bg-gray-500 text-uppercase font-weight-bold h-40px w-40px d-flex justify-content-center align-items-center mr-4" />
                        <div className="flex-grow-1 d-flex flex-column">
                            <div className="skeleton w-50 mb-3 shadow-sm" />
                            <div className="skeleton w-20 shadow-sm" />
                        </div>
                    </div>
                ))}
            </div>
        );

    if (announcement.length === 0)
        return (
            <div>
                <p className="p3 text-center mt-3">
                    {name ? name : "No Announcements"}
                </p>
            </div>
        );
    return (
        <div>
            <Accordion defaultActiveKey="1" className="announcementTree">
                {announcement.map((announcement: any) => (
                    <div
                        key={announcement.id}
                        className="d-flex align-items-center mb-0 mt-2 px-2 py-1 list-view__item rounded"
                    >
                        <Card>
                            <Card.Header>
                                <Accordion.Toggle
                                    as={Button}
                                    variant="secondary"
                                    onClick={() =>
                                        handleAccordionToggle(announcement)
                                    }
                                    eventKey={announcement.id}
                                    className="accordion-header"
                                >
                                    <div className="accordion-content d-flex justify-content-between">
                                        <div className="rounded-circle shadow-sm bg-gray-500 text-uppercase font-weight-bold h-40px w-40px d-flex justify-content-center align-items-center mr-4 user-select-none">
                                            <p className="mb-0">
                                                {announcement.name[0].toUpperCase()}
                                                {announcement.name[0].toUpperCase()}
                                            </p>
                                        </div>
                                        <div className="flex-grow-1">
                                            <h6 className="mb-0 announcemntTitle">
                                                {announcement.name}
                                            </h6>
                                            {/* <p className="p5 mb-0">{announcement.phoneCell}</p> */}
                                        </div>
                                        <div className="popoverAction">
                                            <ManageAnnouncementPopover
                                                announcement={announcement}
                                            />
                                        </div>
                                    </div>
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={announcement.id}>
                                <Card.Body>
                                    <div className="announcementContactList">
                                        <h6 className="contactTitle">
                                            <b>Contacts</b>
                                        </h6>

                                        {checkLoading ? (
                                            <Loading />
                                        ) : announcementContactlist.length ===
                                          0 ? (
                                            <p>No contact found.</p>
                                        ) : (
                                            announcementContactlist.map(
                                                (contact: any) => (
                                                    <div
                                                        key={contact.id}
                                                        className="flex-grow-1"
                                                    >
                                                        <h6 className="mb-0">
                                                            {contact?.name}
                                                        </h6>
                                                        {
                                                            <p className="p5 mb-0">
                                                                {
                                                                    contact?.phoneCell
                                                                }
                                                            </p>
                                                        }
                                                    </div>
                                                )
                                            )
                                        )}
                                    </div>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </div>
                ))}
            </Accordion>
        </div>
    );
};

export default AnnouncementList;
