import { useField, useFormikContext } from "formik";
import React, { InputHTMLAttributes, useEffect, useState } from "react";
import { Form, FormControlProps } from "react-bootstrap";
// ... (other imports)
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { faClock } from "@fortawesome/free-regular-svg-icons";
type IProps = FormControlProps &
    InputHTMLAttributes<HTMLInputElement> & {
        label?: string;
        type?: string;
        noLabel?: boolean;
        hideError?: boolean;
        name: string;
        labelClasses?: string;
        small?: boolean;
        helperText?: string;
        valueOverride?: string;
        required?:boolean;
    };

const CustomInput: React.FC<IProps> = ({
    label,
    noLabel,
    hideError,
    className,
    labelClasses,
    small,
    helperText,
    valueOverride,
    type,
    ...props
}) => {
    const formikContext = useFormikContext();

    const [field, meta] = useField(props.name);
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);

    const togglePasswordVisibility = () => {
        setIsPasswordVisible(!isPasswordVisible);
    };
    useEffect(() => {
        if (!!valueOverride && valueOverride !== meta.initialValue) {
            formikContext.setFieldValue(field.name, valueOverride);
        }
    }, [valueOverride]);

    const paddings = small ? "py-2 px-3" : "py-5 px-6";

    if (type === "tel") {
        return (
            <Form.Group className="" controlId={props.name}>
                {!noLabel && (
                    <Form.Label className={labelClasses}>{label}{props.required && <span className="text-danger">*</span>}</Form.Label>
                )}
                <PhoneInput
                    {...field}
                    {...props}
                    country={"us"}
                    placeholder={props.placeholder || "Phone"}
                    value={field.value}
                    onChange={(value) => {
                        const formattedPhoneNumber = `+${value.replace(
                            /\D/g,
                            ""
                        )}`;

                        formikContext.setFieldValue(
                            field.name,
                            formattedPhoneNumber
                        );
                    }}
                    onBlur={() =>
                        formikContext.setFieldTouched(field.name, true)
                    }
                    inputProps={{
                        // style: { width: inputWidth },
                        style: {
                            width: small ? "80%" : "120%",
                        },
                        className: `${className} form-control h-auto col-12`,
                        // borderColor: "#E4E6EF",
                    }}
                    disableDropdown
                    // Disable automatic country formatting
                    // Hide flags for all countries except the US, India, and Canada
                    onlyCountries={["us", "in", "ca"]}
                />

                {helperText && (
                    <Form.Text className="text-muted">{helperText}</Form.Text>
                )}
                {meta.error && (
                    <Form.Control.Feedback
                        type="invalid"
                        style={{ display: meta.error ? "block" : "none" }}
                    >
                        {meta.error}
                    </Form.Control.Feedback>
                )}
            </Form.Group>
        );
    }
    const containerStyle: React.CSSProperties = {
        position: "relative",
        display: "flex",
    };

    const inputStyle: React.CSSProperties = {
        flex: 1,
    };

    const eyeIconStyle: React.CSSProperties = {
        position: "absolute",
        right: "30px",
        // top: "50%",
        top: "29px",
        transform: "translateY(-50%)",
        cursor: "pointer",
    };

    const clock: React.CSSProperties = {
        position: "absolute",
        right: "30px",
        // top: "50%",
        top: "29px",
        transform: "translateY(-50%)",
    };

    return (
        <>
            <Form.Group className="mb-3 w-100" controlId={props.name}>
                {!noLabel && (
                    <Form.Label className={labelClasses}>{label} {props.required && <span className="text-danger">*</span>}</Form.Label>
                )}

                <div style={containerStyle}>
                    <span style={inputStyle}>
                        <Form.Control
                            type={isPasswordVisible ? "text" : type}
                            {...props}
                            {...field}
                            className={`${className} form-control h-auto ${paddings}`}
                            isInvalid={
                                !!meta.error && meta.touched && !hideError
                            }
                        />
                        {helperText && (
                            <Form.Text className="text-muted">
                                {helperText}
                            </Form.Text>
                        )}

                        {!hideError && (
                            <div className="invalid-feedback">{meta.error}</div>
                        )}
                    </span>

                    {type === "password" && (
                        <FontAwesomeIcon
                            icon={isPasswordVisible ? faEyeSlash : faEye}
                            style={eyeIconStyle}
                            onClick={togglePasswordVisibility}
                        />
                    )}
                    {type === "time" && (
                        <FontAwesomeIcon icon={faClock} style={clock} />
                    )}
                </div>
            </Form.Group>
        </>
    );
};

export default CustomInput;
