import { createAsyncThunk } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { default as axiosClient, default as axiosClients } from "../../helpers/axiosClients";
import { composeDates } from "../../helpers/composeDates";
import { processAsyncThunkAxiosErrors } from "../../helpers/processAsyncThunkAxiosErrors";
import { NewPartyFormValues } from "../../types/Forms";
import { AddGuestFromPartyField, Guest } from "../../types/Guest";
import { Message } from "../../types/Message";
import {
    MessageAnalytics,
    MsgThread,
    MsgThreadPagination,
    MsgThreadSummary
} from "../../types/MsgThread";

interface IFetchCompletedPartiesListParams {
    _page?: number;
    _limit?: number;
}

export const fetchCompletedPartiesList = createAsyncThunk<
    MsgThreadPagination["items"],
    IFetchCompletedPartiesListParams
>("party/fetchCompletedPartiesList", async ({ _limit, _page }, thunkApi) => {
    try {
        const result = await axiosClient.get<MsgThreadPagination>(
            "/msg-thread/parties/completed",
            {
                params: {
                    page_size: _limit,
                    page_number: _page,
                },
            }
        );
        return result.data.items;
    } catch (err: any) {
        return processAsyncThunkAxiosErrors(err, thunkApi);
    }
});

interface IFetchFuturePartiesListParams {
    _page?: number;
    _limit?: number;
}

export const fetchFuturePartiesList = createAsyncThunk<
    MsgThreadPagination["items"],
    IFetchFuturePartiesListParams
>("party/fetchFuturePartiesList", async ({ _limit, _page }, thunkApi) => {
    try {
        const result = await axiosClient.get<MsgThreadPagination>(
            "/msg-thread/parties/future",
            {
                params: {
                    page_size: _limit,
                    page_number: _page,
                },
            }
        );
        return result.data.items;
    } catch (err: any) {
        return processAsyncThunkAxiosErrors(err, thunkApi);
    }
});

interface IFetchTodayPartiesListParams {
    _page?: number;
    _limit?: number;
}

export const fetchTodayPartiesList = createAsyncThunk<
    MsgThreadPagination["items"],
    IFetchTodayPartiesListParams
>("party/fetchTodayPartiesList", async ({ _limit, _page }, thunkApi) => {
    try {
        const result = await axiosClient.get<MsgThreadPagination>(
            "/msg-thread/parties/today",
            {
                params: {
                    page_size: _limit,
                    page_number: _page,
                },
            }
        );

        return result.data.items;
    } catch (err: any) {
        return processAsyncThunkAxiosErrors(err, thunkApi);
    }
});

interface IFetchAllUserPartiesParams {
    fetchInactive: boolean;
}

export const fetchAllUserParties = createAsyncThunk<
    MsgThread[],
    IFetchAllUserPartiesParams
>("party/fetchAllUserParties", async ({ fetchInactive }, thunkApi) => {
    try {
        const result = await axiosClient.get<MsgThread[]>("/msg-thread/my", {
            params: {
                is_active: fetchInactive ? "all" : "true",
                thread_type: "party",
            },
        });
        return result.data;
    } catch (err: any) {
        return processAsyncThunkAxiosErrors(err, thunkApi);
    }
});

export const createParty = createAsyncThunk<MsgThread, NewPartyFormValues>(
    "party/fetchList",
    async (values, thunkApi) => {
        const timeSchedStart = composeDates(
            values.partyStartDate,
            values.partyStartTime
        );

        const timeSchedEnd = composeDates(
            values.partyEndDate,
            values.partyEndTime
        );

        try {
            const result = await axiosClients.post<MsgThread>(
                "/msg-thread",
                {
                    name: values.name,
                    description: "",
                    timeSchedStart: timeSchedStart.toISOString(),
                    timeSchedEnd: timeSchedEnd.toISOString(),
                    timezone: dayjs.tz.guess(),
                    welcomeMsg: "",
                    scriptId: 0,
                    host: values.host
                },
                {
                    params: {
                        thread_type: "party",
                    },
                }
            );
            return result.data;
        } catch (err: any) {
            return processAsyncThunkAxiosErrors(err, thunkApi);
        }
    }
);

interface IDeleteMsgThreadParams {
    id: number;
}

export const deleteParty = createAsyncThunk<void, IDeleteMsgThreadParams>(
    "party/deleteParty",
    async ({ id }, thunkApi) => {
        try {
            await axiosClient.delete(`/msg-thread/${id}`);
        } catch (err: any) {
            return processAsyncThunkAxiosErrors(err, thunkApi);
        }
    }
);

export const startParty = createAsyncThunk<void, IMsgThreadParams>(
    "party/startParty",
    async ({ id }, thunkApi) => {
        try {
            const result = await axiosClient.patch(`/msg-thread/start/${id}`);
            return result.data;
        } catch (err: any) {
            return processAsyncThunkAxiosErrors(err, thunkApi);
        }
    }
);

interface IMsgThreadParams {
    id: number;
}
export const resetParty = createAsyncThunk<void, IMsgThreadParams>(
    "party/resetParty",
    async ({ id }, thunkApi) => {
        try {
            const result = await axiosClient.patch(`/msg-thread/${id}/reset`);
            return result.data;
        } catch (err: any) {
            return processAsyncThunkAxiosErrors(err, thunkApi);
        }
    }
);

export const resumeParty = createAsyncThunk<void, IMsgThreadParams>(
    "party/resumeParty",
    async ({ id }, thunkApi) => {
        try {
            const result = await axiosClient.patch(`/msg-thread/${id}/resume`);
            return result.data;
        } catch (err: any) {
            return processAsyncThunkAxiosErrors(err, thunkApi);
        }
    }
);

export const pauseParty = createAsyncThunk<void, IMsgThreadParams>(
    "party/pauseParty",
    async ({ id }, thunkApi) => {
        try {
            const result = await axiosClient.patch(`/msg-thread/${id}/pause`);
            return result.data;
        } catch (err: any) {
            return processAsyncThunkAxiosErrors(err, thunkApi);
        }
    }
);

export const stopParty = createAsyncThunk<void, IMsgThreadParams>(
    "party/stopParty",
    async ({ id }, thunkApi) => {
        try {
            const result = await axiosClient.patch(`/msg-thread/end/${id}`);
            return result.data;
        } catch (err: any) {
            return processAsyncThunkAxiosErrors(err, thunkApi);
        }
    }
);

interface IFetchSelectedPartyParams {
    partyId: number;
}

export const fetchSelectedParty = createAsyncThunk<
    MsgThread,
    IFetchSelectedPartyParams
>("party/fetchSelectedParty", async ({ partyId }, thunkApi) => {
    try {
        const result = await axiosClient.get<MsgThread>(
            `/msg-thread/by-id/${partyId}`
        );
        return result.data;
    } catch (err: any) {
        return processAsyncThunkAxiosErrors(<any>err, thunkApi);
    }
});

interface IFetchSelectedPartyGuestsParams {
    partyId: number;
    _page?: number;
    _limit?: number;
    search?: string;
}

export const fetchSelectedPartyGuests = createAsyncThunk<
    Guest[],
    IFetchSelectedPartyGuestsParams
>("party/fetchSelectedPartyGuests", async ({ partyId,_page,_limit,search }, thunkApi) => {
    try {
        const result = await axiosClient.get<Guest[]>(
            `/guest/by-msg-thread/${partyId}`,{
                params: {
                    page: _page,
                    limit: _limit,
                    search
                }
            });
        return result.data;
    } catch (err : any) {
        return processAsyncThunkAxiosErrors(err, thunkApi);
    }
});

export const fetchSelectedPartyGuestsPaginate = createAsyncThunk<
    Guest[],
    IFetchSelectedPartyGuestsParams
>("party/fetchSelectedPartyGuestsPaginate", async ({ partyId,_page,_limit,search }, thunkApi) => {
    try {
        const result = await axiosClient.get<Guest[]>(
            `/guest/by-msg-thread/${partyId}`,{
                params: {
                    page: _page,
                    limit: _limit,
                    search
                }
            });
        return result.data;
    } catch (err: any) {
        return processAsyncThunkAxiosErrors(err, thunkApi);
    }
});

interface IFetchGuestsForPartyParams {
    partyId: number;
}
// Use when you need to fetch guest list for new direct message modal
export const fetchGuestsForParty = createAsyncThunk<
    Guest[],
    IFetchGuestsForPartyParams
>("party/fetchGuestsForParty", async ({ partyId }, thunkApi) => {
    try {
        const result = await axiosClient.get<Guest[]>(
            `/guest/by-msg-thread/${partyId}`
        );
        return result.data;
    } catch (err: any) {
        return processAsyncThunkAxiosErrors(err, thunkApi);
    }
});

interface IUpdateParty {
    id?: number;
    scriptId?: number | null;
    name?: string;
    description?: string;
    timeSchedStart?: string;
    timeSchedEnd?: string;
    timezone?: string;
    welcomeMsg?: string;
    isActive?: boolean;
}

export const updateParty = createAsyncThunk<MsgThread, IUpdateParty>(
    "party/updateParty",
    async ({ id, ...data }, thunkApi) => {
        try {
            const result = await axiosClient.patch<MsgThread>(
                `/msg-thread/${id}`,
                data
            );
            return result.data;
        } catch (err: any) {
            return processAsyncThunkAxiosErrors(err, thunkApi);
        }
    }
);

interface IFetchPartySummaryParams {
    partyId: string;
}

export const fetchPartySummary = createAsyncThunk<
    MsgThreadSummary,
    IFetchPartySummaryParams
>("party/fetchPartySummary", async ({ partyId }, thunkApi) => {
    try {
        const result = await axiosClient.get<MsgThreadSummary>(
            `/msg-thread/parties/by-partyId/${partyId}`
        );
        return result.data;
    } catch (err: any) {
        return processAsyncThunkAxiosErrors(err, thunkApi);
    }
});

interface ICreateGuestForPartyParams {
    msgThreadId: number;
    phoneCell: string;
    phoneCountryCode: string;
    gatewayId: number;
    nameFirst: string;
    nameLast: string;
    nameAlias: string;
    email: string;
    isHost: boolean;
}

export const createGuestForParty = createAsyncThunk<
    Guest,
    ICreateGuestForPartyParams
>("party/createGuestForParty", async (data, thunkApi) => {
    try {
        const result = await axiosClient.post<Guest>("/guest", data);
        return result.data;
    } catch (err: any) {
        return processAsyncThunkAxiosErrors(err, thunkApi);
    }
});

export const createGuestForPartySellerOnly = createAsyncThunk<
    Guest,
    ICreateGuestForPartyParams
>("party/createGuestForPartySellerOnly", async (data, thunkApi) => {
    try {
        const result = await axiosClient.post<Guest>(
            "/guest/create-for-seller",
            data
        );
        return result.data;
    } catch (err: any) {
        return processAsyncThunkAxiosErrors(err, thunkApi);
    }
});

interface IRemoveGuestParams {
    guestId: number;
}

export const removeGuestFromParty = createAsyncThunk<any, IRemoveGuestParams>(
    "party/removeGuestFromParty",
    async ({ guestId }, thunkApi) => {
        try {
            const res = await axiosClient.delete(`/guest/${guestId}`); 
            return res.data;
        } catch (err: any) {
            return processAsyncThunkAxiosErrors(err, thunkApi);
        }
    }
);

interface IRemoveGuestParams_ {
    guests: any;
}
export const deleteAllPartyGuests = createAsyncThunk<void, IRemoveGuestParams_>(
    "party/deleteAllPartyGuests",
    async ({ guests }, thunkApi) => {
        try {
            // for (const guest of guests) {
            //     await axiosClient.delete(`/guest/${guest.id}`);
            // }

            //below code working
            await axiosClient.post("/guest/delete-all", guests);
 
        } catch (err) {
            return thunkApi.rejectWithValue(err);
        }
    }
);

interface IFetchSelectedPartyMessages {
    id: number;
}

export const fetchSelectedPartyMessages = createAsyncThunk<
    Message[],
    IFetchSelectedPartyMessages
>("party/fetchSelectedPartyMessages", async ({ id }, thunkApi) => {
    try {
        const result = await axiosClient.get<Message[]>(
            `/message/by-msg-thread/${id}`
        );
        return result.data;
    } catch (err: any) {
        return processAsyncThunkAxiosErrors(err, thunkApi);
    }
});
interface IFetchSelectedPartyMessagesAnalytics {
    id: number;
}

export const fetchSelectedPartyMessageAnalytics = createAsyncThunk<
    MessageAnalytics[],
    IFetchSelectedPartyMessagesAnalytics
>("party/fetchSelectedPartyMessageAnalytics", async ({ id }, thunkApi) => {
    try {
        const result = await axiosClient.get<MessageAnalytics[]>(
            `/message-analytics/${id}`
        );
        return result.data;
    } catch (err: any) {
        return processAsyncThunkAxiosErrors(err, thunkApi);
    }
});

interface ISendMessageToTheSelectedParty {
    msgThreadId: number;
    messageText: string;
    mediaUrlId: any;
}

export const sendMessageToTheSelectedParty = createAsyncThunk<
    Message,
    ISendMessageToTheSelectedParty
>("party/sendMessageToTheSelectedParty", async (data, thunkApi) => {
    try {
        const result = await axiosClient.post<Message>(
            "/message/by-seller/",
            data
        );
        return result.data;
    } catch (err: any) {
        return processAsyncThunkAxiosErrors(err, thunkApi);
    }
});
interface ICreateGuestFromContactParams {
    msgThreadId?: number;
    contactId: number[];
    isHost: boolean;
}

export const addGuestFromContact = createAsyncThunk<
    Guest,
    ICreateGuestFromContactParams
>("party/createGuestFromContact", async (data, thunkApi) => {
    try {
        const result = await axiosClient.post<Guest>("/guest/by-contact", data);
        return result.data;
    } catch (err: any) {
        return processAsyncThunkAxiosErrors(err, thunkApi);
    }
});


interface ICreateGuestToPartyParams {
    partyId?: any;
    guestId: number[];
}

export const addGuestToParty = createAsyncThunk<
     AddGuestFromPartyField,
    ICreateGuestToPartyParams
>("addInAnnoucement/create", async (values, thunkApi) => {
    try {
        const result = await axiosClients.post<AddGuestFromPartyField>(
            "/guest/add-guests",
            {
                msgThreadId: values.partyId,
                guestIds: values.guestId,
            }
        );
        return result.data;
    } catch (err:any) {
        return processAsyncThunkAxiosErrors(err, thunkApi);
    }
});

